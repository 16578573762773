import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { NgxXml2jsonService } from "ngx-xml2json";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RequestOptions, Headers, Http } from "@angular/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AwsS3UploadService {
  resourceUrl: string = environment.baseUrl + "/s3Policy";
  allQuery: any = {};

  constructor(
    private httpClient: HttpClient,
    private http: Http,
    private xml2js: NgxXml2jsonService
  ) {}

  find(req?: any): Observable<any> {
    this.allQuery = {};
    this.allQuery.type = req.type;
    this.allQuery.mimeType = req.mimeType;
    const params = new HttpParams().set("obj", this.allQuery);
    return this.httpClient
      .get(`${this.resourceUrl}?mimeType=` + req.mimeType + `&type=` + req.type)
      .map(res => {
        return res;
      });
  }

  uploadS3(file: any, s3credentials: any): Promise<any> {
    // console.log("s3credentials",s3credentials,file);
    /*let filename = file.name.substr(0, file.name.lastIndexOf('.'));
		console.log("filename",filename);*/
    let headers = new Headers();
    //headers.append('X-Host', 's3.amazonaws.com');
    let options = new RequestOptions({ headers: headers });
    var timeStamp = Math.floor(Date.now());
    //var fileName = this.user.id + timeStamp;

    let formData = new FormData();

    formData.append(
      "key",
      s3credentials.keyPath + timeStamp + "." + file.type.substring(12)
    );
    formData.append("acl", s3credentials.acl);
    formData.append("success_action_status", "201");
    formData.append("Policy", s3credentials.s3Policy);
    formData.append("X-Amz-Credential", s3credentials.credentials);
    formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");

    formData.append("X-Amz-Date", s3credentials.date);

    formData.append("X-Amz-Signature", s3credentials.s3Signature);

    formData.append("Content-Type", file.type);
    formData.append("file", file);

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `https://${s3credentials.bucket}.s3.amazonaws.com/`,
          formData,
          options
        )
        .map(res => res.text())
        .subscribe(
          response => {
            // let awsRspObj = this.xml2js.xmlToJson(response);
            const parser = new DOMParser();
            const xml = parser.parseFromString(response, "text/xml");
            const obj = this.xml2js.xmlToJson(xml);
            resolve(obj);
          },
          x => {
            console.error(x);
            reject();
          }
        );
    });
  }
}
